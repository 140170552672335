export const host = "https://dongqian-api-zhenxin.shooter-sh.com:10585/";//开发用/
//export const host = "https://dongqian-api-demo.shooter-sh.com/";//开发用/
// export const host = "https://dongqian-api-anting.shooter-sh.com:10481/";
// export const host = "https://dongqian-api-jiangqiao.shooter-sh.com:10482/";

export const LoginUrl = `${host}ajax/LogOn`; 
//返回签约数
export const GetStatusViewCount = `${host}ajax/GetStatusViewCount`; 

//分页获取分组数据接口：
export const GetHoseTypeAll = `${host}ajax/GetHoseTypeAll`;
export const getHoseType = `${host}ajax/GetHoseType`;
export const HoseGroupCreateOrUpdate = `${host}ajax/HoseGroupCreateOrUpdate`;
export const HoseGroupFileUpload = `${host}upload_json.ashx`;
export const HoseGroupDelete = `${host}ajax/HoseGroupDelete`; 

//分页获取 房源 分组数据接口：
export const getHoseGroup = `${host}ajax/GetHoseGroup`;
export const getHoseGroupFile = `${host}ajax/GetHoseGroupFile?`;
export const CreateOrUpdateHoseView = `${host}ajax/CreateOrUpdateHoseView`;
export const HoseViewDelete = `${host}ajax/HoseViewDelete`; 


//抽签接口：
export const GetRandomHoseGroupView = `${host}ajax/GetRandomHoseGroupView`;
export const BindHose = `${host}ajax/BindHose`;


